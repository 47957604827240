import {GarudaApi} from "../../infrastructure/garudaApi";
import {FileDownloadModule} from "../file-download-module";
import {MimeTypeEnum} from "../../models/enums/mimeTypeEnum";

export class ReportNotificationsModule {
    private garudaApi: GarudaApi;
    private fileDownloadModule: FileDownloadModule;

    private dropdownMenuSelector = "#notification-dropdown";
    private notificationDropdownBtnSelector = "#notification-dropdown-btn";
    private notificationDropdownElementSelector = ".notification-dropdown-element";
    private clearListBtnSelector = ".notification-dropdown-clear-list-btn";
    private notificationBadgeSelector = "#notification-badge";

    constructor() {
        this.garudaApi = new GarudaApi();
        this.fileDownloadModule = new FileDownloadModule();
    }

    public async initReportNotifications() {
        await this.updateNotificationBadge();

        $(this.notificationDropdownBtnSelector).off("click").on("click", async () => {
            await this.updateNotificationDropdownContent();
        });

        $(this.dropdownMenuSelector).off("click").on("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
        });

        setInterval(async () => {
            await this.updateNotificationBadge();
        }, 8000);
    }

    private async clearNotificationList() {
        await this.garudaApi.deleteReportNotifications();
    }

    private async updateNotificationDropdownContent() {
        const dropdownElements = await this.garudaApi.reportNotificationContent();

        $(this.notificationDropdownElementSelector).remove();
        $(this.dropdownMenuSelector).html(dropdownElements);

        $(this.notificationDropdownElementSelector).off("click").on("click", async (event) => {
            const status = $(event.currentTarget).data("report-status");
            if (status === "Success") {
                const reportId = $(event.currentTarget).data("reportId");
                const reportMimeType = $(event.currentTarget).data("reportMimetype");
                const reportName = $(event.currentTarget).data("reportName");
                const mimeType: MimeTypeEnum = MimeTypeEnum[reportMimeType as keyof typeof MimeTypeEnum];

                const fileUrl = this.garudaApi.getDownloadReportUrl(reportId, reportMimeType);

                await this.fileDownloadModule.OpenReport(fileUrl, mimeType);
                await this.updateNotificationBadge();

                $(this.notificationDropdownBtnSelector).trigger("click");
            } else {
                event.stopPropagation();
            }
        });

        this.bindClearListBtn();
    }

    private bindClearListBtn() {
        $(this.clearListBtnSelector).off("click").on("click", async (event) => {
            event.preventDefault();

            await this.clearNotificationList();
            await this.updateNotificationBadge();
            await this.updateNotificationDropdownContent();
        });
    }

    private async updateNotificationBadge() {
        const notifications = await this.garudaApi.reportNotifications();

        const notificationBadge = $(this.notificationBadgeSelector);
        const previousNotifications = +notificationBadge.text();
        notificationBadge.text(notifications > 9 ? "9+" : notifications);

        if (notifications > 0) {
            notificationBadge.show();
        } else {
            notificationBadge.hide();
        }

        if (notifications !== previousNotifications) {
            await this.updateNotificationDropdownContent();
        }
    }
}
