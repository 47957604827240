import { NotificationModule } from "ditmer-embla";
import {Localization} from "../localization-module";
import {GarudaApi} from "../../infrastructure/garudaApi";

export class AsyncErrorModule {
    private body: any;
    private garudaApi = new GarudaApi();

    private constructor() {
    }

    public static CreateFromJQueryXHR(xhr: JQuery.jqXHR): Promise<AsyncErrorModule> {
        let promise = new Promise<AsyncErrorModule>((resolve, reject) => {

            let asyncErrorModule = new AsyncErrorModule();

            if(xhr.responseJSON !== undefined)
                asyncErrorModule.body = xhr.responseJSON;
           else
                asyncErrorModule.body = xhr.responseText;

            resolve(asyncErrorModule);
        });

        return promise;
    }

    public static CreateFromResponse(response: Response): Promise<AsyncErrorModule> {
        let promise = new Promise<AsyncErrorModule>((resolve, reject) => {
            response.json().then(body => {
                let asyncErrorModule = new AsyncErrorModule();
                asyncErrorModule.body = body;
                resolve(asyncErrorModule);
            });
        });

        return promise;
    }

    getResponseBody() {
        return this.body;
    }

    getIsValidationError() {
        let asyncErrorMessage = this.getResponseBody() as AsyncErrorMessage;

        if (asyncErrorMessage === undefined || asyncErrorMessage.ErrorMessage !== "ValidationError")
            return false;

        return true;
    }

    handleError() {

        if (this.getIsValidationError() === true) {

            this.showValidationErrorNotification();
            this.showValidationErrorOnInputs();

            return;
        }



        //Default handler
        NotificationModule.showErrorSmall(Localization.getText("Global_Communication_Error"))
        this.postErrorToApi(this.body);
        console.log("Default error handler", this.body);
    }

    postErrorToApi(errorMessage: string) {
            this.garudaApi.postErrorToApi(errorMessage)
    }

    getResponseAsValidationErrorList() {

        let validationErrorList: ValidationError[] = [];
        let asyncErrorMessage = this.getResponseBody() as AsyncErrorMessage;

        for (var key in asyncErrorMessage.ErrorData) {

            let htmlDecodedKey = $('<textarea />').html(key).text();
            let htmlDecodedValue = $('<textarea />').html(asyncErrorMessage.ErrorData[key]).text();

            validationErrorList.push({
                key: htmlDecodedKey,
                errorMessage: htmlDecodedValue
            });
        }

        return validationErrorList;
    }

    showValidationErrorNotification() {

        let validationErrorList = this.getResponseAsValidationErrorList();

        let listHtml = "<ul>";

        validationErrorList.forEach((validationError) => {
            listHtml += "<li>" + validationError.errorMessage + "</li>";
        })

        listHtml += "</ul>";

        NotificationModule.showErrorSmall("Der skete følgende valideringsfejl:<br/>" + listHtml);
    }

    showValidationErrorOnInputs() {

        let validationErrorList = this.getResponseAsValidationErrorList();

        validationErrorList.forEach((validationError) => {
            let input = $("input[name='" + validationError.key + "']");
            input.addClass("input-validation-error")
        });
    }
}


type errorType = "ValidationError" | "";

export interface AsyncErrorMessage {

    ErrorMessage: errorType;
    ErrorData: any

}

export interface ValidationError {
    key: string,
    errorMessage: string
}



