import "core-js"; //IE
import {
    initEmbla,
    LeftMenuModule,
    DropdownModule,
    NotificationModule
} from "ditmer-embla";
import * as moment from "moment";
import "moment/locale/da";
import { AsyncErrorModule } from "./modules/asyncError-module";
import {ReportNotificationsModule} from "./modules/report-notifications-module";
import {Localization} from "./modules/localization-module";

declare global {
    interface Window {
        successMessage: string;
        infoMessage: string;
        errorMessage: string;
    }

    interface Array<T> {
        selectMany<TOut>(selectListFn: (t: T) => TOut[]): TOut[];
        distinct(selectFn?: (t: T, t2: T) => boolean): Array<T>;
    }

    interface String {
        format(): string;
    }

    interface Promise<T> {
        _onerror(error : any) : void;
    }
}

Promise.prototype._onerror = function(err) {
    console.error(err);
    // throw err; // TODO: Den her gør at promises breaker hvis der sker en fejl. Er det det vi vil?
}

Array.prototype.distinct = function(fn?: (a: any, b: any) => boolean) {
    if(fn) {
        const newArr: Array<any> = [];
        this.forEach((val: any) => {
            if(newArr.length === 0) {
                newArr.push(val);
            } else {
                if(!newArr.some(x => fn(val, x))) {
                    newArr.push(val);
                }
            }
        });
        return newArr;
    }
    return this.filter((val: any, index: number, arr: Array<any>) => arr.indexOf(val) === index);
};

Array.prototype.selectMany = function(x) {
    return this.map(x).reduce((a: any, b: any) => a ? a.concat(b) : [],  []);
};

String.prototype.format = String.prototype.format ||
    function () {
        "use strict";
        var str = this.toString();
        if (arguments.length) {
            var t = typeof arguments[0];
            var key;
            var args = ("string" === t || "number" === t) ?
                Array.prototype.slice.call(arguments)
                : arguments[0];

            for (key in args) {
                str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
            }
        }

        return str;
    };

// Disable ajax caching
$.ajaxSetup({ cache: false, traditional: true });

$(document).ajaxError((event, jqXHR, settings, thrownError) => {
    console.log("ajax error", "xhr", jqXHR, "thrownError", thrownError, "responseText", jqXHR.responseText);

    if (jqXHR.status === 401){
        window.location.href = "Account/Login";
    }

    if (thrownError === "abort") { // Do nothing if error is an aborted ajax request
        return;
    }

    AsyncErrorModule.CreateFromJQueryXHR(jqXHR)
        .then((asyncErrorModule) => {
            asyncErrorModule.handleError();
        });
});

// Default select2 language to danish
($.fn as any).select2.defaults.set("language", "da");

(function () {
    $(() => {
        initEmbla();

        $("body").removeClass("preload"); // ".preload" class is fix for IE transition jump on page loads - it should be removed on $(document).ready(). See https://css-tricks.com/transitions-only-after-page-load/

        if ($(".left-menu-header-toggle").length > 0) {
            new LeftMenuModule({
                toggleMenuSelector: ".left-menu-header-toggle",
                persistState: true,
                persistStateForDays: 1
            });
        }

        initDropdowns();
        new ReportNotificationsModule().initReportNotifications(); //NOTE: This throws a console error when the user is not logged in.

        // Setup date validation
        jQuery.validator.addMethod("date",
            function (value: string, element: any) {

                if (value === "") {
                    return this.optional(element);
                }

                const momentDate = moment(value, ["DD-MM-YYYY HH:mm", "DD-MM-YYYY"], true);

                return this.optional(element) || momentDate.isValid();
            });

        // Setup number validation
        let defaultNumberValidatorMethod: (value: string, element: Element) => boolean = ($ as any).validator.methods.number;
        jQuery.validator.addMethod("number", function (value: string, element: Element) {

            defaultNumberValidatorMethod = defaultNumberValidatorMethod.bind(this); // bind this, so "this" variable is correct in defaultNumberValidatorMethod

            const numericInputModule = $(element).data("numericinput-module");

            if (numericInputModule === undefined) { // Return default number validator, if not using numeric input module
                return defaultNumberValidatorMethod(value, element);
            }

            return defaultNumberValidatorMethod(numericInputModule.getRawValue(), element);
        });

        // Notificationer beskeder
        if (window.successMessage !== undefined && window.successMessage.length > 0) {
            const successMessages = window.successMessage.split(";");
            for (const message of successMessages) {
                NotificationModule.showSuccessSmall(message);
            }
        }
        if (window.infoMessage !== undefined && window.infoMessage.length > 0) {
            const infoMessages = window.infoMessage.split(";");
            for (const message of infoMessages) {
                NotificationModule.showInfoSmall(message);
            }
        }
        if (window.errorMessage !== undefined && window.errorMessage.length > 0) {
            const errorMessages = window.errorMessage.split(";");
            for (const message of errorMessages) {
                NotificationModule.showErrorSmall(message);
            }
        }
    });
})();

function initDropdowns() {
    new DropdownModule().init("select:not(.no-select2)", {
        additionalSelect2Options: {
            language: {
                noResults: function () {
                    return Localization.getText("Global_NoResult");
                }
            }
        }
    });
}

export const debounce = (callback: any, delayInMs: number) => {
    let timeoutHandler: any = null;
    return (...args: any[]) => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler)
      }

      timeoutHandler = setTimeout(() => {
        callback(...args);
        timeoutHandler = null
      }, delayInMs)
    }
  }
